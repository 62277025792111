import { Flatten, PickFirst } from '../services/utils'
import { HomeQuery } from '../queries/home'
import { GQLClient } from '../services/graphql'

// import { nanoid } from 'nanoid'

import PageLayout from 'layouts/PageLayout'
// import TextBlock from 'layouts/TextBlock'
// import SupportingContent from 'layouts/SupportingContent'

import Masthead from 'features/Masthead'
import Footer from 'features/Footer'
// import LoginBox from 'features/LoginBox'
import Hero from 'features/Hero'

import Spacer from 'basics/Spacer'
import CtaBanner from 'features/CtaBanner'
import SupportingContentBlocks from 'features/SupportingContentBlocks'
// import CtaWidgets from 'features/CtaWidgets'
import { GetCachedData, GetMetaData } from '../services/data.service'
import { HandleResources } from 'services/resources.service'
import Resources from 'features/Resources'

const Home = ({
                pageData = {},
                meta = {},
                topNav = [],
                mainNav = [],
                footerContent = {},
                applicationSettings = {}
              }) => {
  const { resources = [] } = pageData

  return (
    <PageLayout meta={meta} applicationSettings={applicationSettings || {}}>
      <Masthead
        isHome={ true }
        topLinks={ topNav?.links || [] }
        mainLinks={ mainNav?.links || [] }
      />

      {pageData.hero && (
        <Hero data={pageData.hero[0] || {}} isHome={true}/>
      )}

      {/* <SupportingContent type="66:33" gridGap={50} className="mb-20 sm-13" key={nanoid()}>
        <TextBlock className="md-mb-20">
          <h1 className="white-space-pre-wrap">{PickFirst(pageData.hero)?.headline}</h1>

          <Spacer size={4} />

          <div className="intro-paragraph">{PickFirst(pageData.hero)?.subheadline}</div>

          <Spacer size={5} />

          <CtaWidgets type="primaryLight" ctaWidgets={PickFirst(pageData.hero)?.ctaWidgets} />
        </TextBlock>

        <LoginBox />
      </SupportingContent> */}

      <Spacer size={20} />

      <SupportingContentBlocks blocks={pageData.supportingContent} />

      <Resources resources={resources} className="mb-20 md-mb-13" />

      <CtaBanner data={PickFirst(pageData.footerCTA)} />

      <Footer
        navLinks={topNav?.links || []}
        footerLinks={mainNav?.links || []}
        footerContent={footerContent}
      />
      {/* Floodlight */}
      {/* Page view */}
      <script
        dangerouslySetInnerHTML={{
          __html: `gtag('event', 'conversion', {
          'allow_custom_scripts': true,
          'send_to': 'DC-13333447/pagev0/fbbla0+standard'
        });`
        }}
      ></script>
      <noscript>
        <img src="https://ad.doubleclick.net/ddm/activity/src=13333447;type=pagev0;cat=fbbla0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>
      </noscript>

      {/* Phone Number */}
      <script
        dangerouslySetInnerHTML={{
          __html: `gtag('event', 'conversion', {
          'allow_custom_scripts': true,
          'send_to': 'DC-13333447/phone0/fbb_p0+standard'
        });`
        }}
      ></script>
      <noscript>
        <img src="https://ad.doubleclick.net/ddm/activity/src=13333447;type=phone0;cat=fbb_p0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>
      </noscript>

      {/* Sign Me Up */}
      <script
        dangerouslySetInnerHTML={{
          __html: `gtag('event', 'conversion', {
          'allow_custom_scripts': true,
          'send_to': 'DC-13333447/signm0/fbb_s0+standard'
        });`
        }}
      ></script>
      <noscript>
        <img src="https://ad.doubleclick.net/ddm/activity/src=13333447;type=signm0;cat=fbb_s0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>
      </noscript>

      {/* Universal */}
      <script
        dangerouslySetInnerHTML={{
          __html: `gtag('event', 'conversion', {
          'allow_custom_scripts': true,
          'send_to': 'DC-13333447/unive0/fbb_u0+standard'
        });
      `
        }}
      ></script>
      <noscript>
        <img src="https://ad.doubleclick.net/ddm/activity/src=13333447;type=unive0;cat=fbb_u0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>
      </noscript>


    </PageLayout>
  )
}

export default Home

export async function getStaticProps({ preview = false }) {
  const client = await GQLClient()
  const data = await client.request(HomeQuery, {}).catch((err) => console.log(err))
  const fullData = Flatten(GetCachedData(data))
  fullData.pageData = PickFirst(fullData.queryHomeContents)
  fullData.meta = GetMetaData(fullData?.pageData?.pageBase)
  fullData.pageData = await HandleResources({ pageData: fullData.pageData, force: true })
  return {
    props: fullData
  }
}
